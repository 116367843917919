import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Row, Col, message } from 'antd'
import { graphql } from 'gatsby'
import { useRequest } from 'ahooks'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import Cookies from 'js-cookie'
import { Box, useMediaQuery } from '@mui/material'
import { GlobalAntdFormStyle } from '@/components/GlobalCSS/AntdFormStyle'
import { Footer, Header, SEO, Helmet } from '@/components'
import { Form, FormItem, FormItemProps } from '@/components/EnquireForm/Form'
import { APIqueryBizDictData } from '@/components/EnquireForm/api'
import PrivacyAgreement from '@/components/PrivacyAgreement'
import { usePrivacyNoticeModal } from '@/components/PrivacyNoticeModal'

const EMAIL_REG = /\S+\@\S+\.\S+/ // @.格式就可以

export default (props) => {
  const {} = useTranslation()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const footerLocale = JSON.parse(props.data.footerLocale.data)
  const isPc = useMediaQuery('@media (min-width: 800px)')
  const [form] = Form.useForm()
  const [isFinished, setIsFinished] = useState<boolean>(false)
  const [countryAreaCode, setCountryAreaCode] = useState<Record<string, any>[]>([])
  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false)
  const [checkedFlag, setCheckedFlag] = useState<string[]>([])
  const { language } = useI18next()

  const {
    title,
    discription,
    formGroupFieldList,
    submit,
    countriesInterestOptionList,
    successTitle,
  } = pageLocale

  // 加盟商问询
  const APIFranchiseeEnquire = (data) => {
    const url = '/flash/franchisee/enquire'
    return window
      .fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', lang: Cookies.get('EXPRESS_LANG') },
        body: JSON.stringify(data),
      } as any)
      .then((res) => {
        return res.json()
      })
      .catch((err) => {
        return err
      })
  }

  // 地区对应手机号前缀获取
  const { run: getWebCountryAreaCode } = useRequest(APIqueryBizDictData, {
    manual: true,
    onSuccess: (res) => {
      if (res.resultObject?.WebCountryAreaCode?.length > 0) {
        const formatData = res.resultObject.WebCountryAreaCode.map((item) => ({
          ...item,
          value: item.key,
          label: `${item.value} ${item.key}`,
        }))
        setCountryAreaCode(formatData || [])
      } else {
        setCountryAreaCode([])
      }
    },
  })

  // 加盟商表单提交
  const { run: queryFranchiseeEnquire } = useRequest(APIFranchiseeEnquire, {
    manual: true,
    onSuccess: (res) => {
      if (res.resultCode === 'success' || res.status === 'success') {
        setShowSuccessPage(true)
      }
    },
  })

  const customColSpanPc = useCallback((name): number => {
    switch (name) {
      case 'interCallingCode':
        return 4
      case 'phoneNumber':
        return 8
      default:
        return 12
    }
  }, [])

  const customColSpan = useCallback((name) => {
    switch (name) {
      case 'interCallingCode':
        return 8
      case 'phoneNumber':
        return 16
      default:
        return 24
    }
  }, [])

  // 自定义lable展示
  const customRequired = useCallback((label, required) => {
    return (
      <Box>
        {label}
        {!label ? null : required ? <span className='required-style'>*</span> : null}
      </Box>
    )
  }, [])

  const localCountry = Cookies.get('COUNTRY')
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false)

  const handleCheckboxChange = useCallback((e) => {
    setCheckedFlag(e)
    setIsPrivacyChecked(Boolean(e?.length))
  }, [])

  const handlePrivacyPolicyShow = () => {
    window.open('/privacy-policy')
  }

  const formList: FormItemProps[] = useMemo(() => {
    return [
      {
        type: 'Input',
        name: 'firstName',
        label: '',
        rules: [{ max: 50, message: formGroupFieldList[0].maxLengthMsg }],
      },
      {
        type: 'Input',
        name: 'lastName',
        label: '',
        rules: [{ max: 50, message: formGroupFieldList[1].maxLengthMsg }],
      },
      {
        type: 'Input',
        name: 'email',
        label: '',
        rules: [{ pattern: EMAIL_REG, message: formGroupFieldList[2].errMsg }],
      },
      {
        type: 'Select',
        name: 'interCallingCode',
        label: '',
        data: countryAreaCode,
        showSearch: true,
        optionValue: 'value',
        optionLabel: 'label',
        rules: [],
      },
      {
        type: 'Input',
        name: 'phoneNumber',
        label: '',
        rules: [],
      },
      {
        type: 'Input',
        name: 'companyName',
        label: '',
        rules: [{ max: 100, message: formGroupFieldList[5].maxLengthMsg }],
      },
      {
        type: 'Select',
        name: 'country',
        label: '',
        mode: 'multiple',
        data: countriesInterestOptionList,
        optionValue: 'key',
        optionLabel: 'value',
        rules: [],
      },
      {
        ...(localCountry !== 'Italy' && localCountry !== 'Australia'
          ? {}
          : {
              type: 'Checkbox',
              name: 'isPrivacyAgreement',
              label: '',
              textColor: '#525566',
              options: [
                {
                  label: footerLocale.我已阅读并同意,
                  value: 'true',
                },
              ],
              rules: [],
              afterContent: <PrivacyAgreement pageLocale={footerLocale} />,
              onChange: handleCheckboxChange,
            }),
      },
    ].filter(Boolean)
  }, [formGroupFieldList, EMAIL_REG, countryAreaCode, countriesInterestOptionList])

  const fields = useMemo(() => formList.map((item) => item.name), [formList])

  const commonRules = useCallback(
    (idx, name) => {
      return {
        required: fields.includes(name),
        message: formGroupFieldList?.[idx]?.requiredMsg,
      }
    },
    [fields],
  )

  const handleClick = useCallback(() => {
    if (isFinished) {
      form
        .validateFields(fields)
        .then((data) => {
          const params = {
            ...data,
            country: data.country?.toString(),
          }
          queryFranchiseeEnquire(params)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [isFinished])

  const handleSubmit = () => {
    if (localCountry !== 'Italy' && localCountry !== 'Australia') {
      handleClick()
    } else {
      isPrivacyChecked ? handleClick() : message.warning(footerLocale.请阅读并同意)
    }
  }

  const { modalContent, onModalOpen } = usePrivacyNoticeModal({
    handleClick,
    form,
    subKey: 'isPrivacyAgreement',
    setCheckedFlag,
    domRoot: 'privacyModalRoot',
  })

  // 表单校验项未填写全按钮置灰不可提交
  const handleValuesChange = useCallback(
    (_, allValues) => {
      const flag = Object.values(allValues).every((item) => {
        // 多选组件值array
        if (Array.isArray(item)) {
          return item.length > 0
        } else {
          return item || item === 0
        }
      })
      flag ? setIsFinished(true) : setIsFinished(false)
    },
    [setIsFinished],
  )

  const finalFormList: FormItemProps[] = useMemo(
    () =>
      formList.map((item, idx) => ({
        ...item,
        label: formGroupFieldList?.[idx]?.field, // 静态资源无需判空
        rules: [commonRules(idx, item.name), ...(item.rules || [])],
        placeholder: formGroupFieldList?.[idx]?.placeholder,
      })),
    [formList, formGroupFieldList, commonRules],
  )

  const contentDomPc = useMemo(() => {
    return (
      <Box>
        {showSuccessPage ? (
          <Box
            sx={{
              minHeight: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '150px',
            }}
          >
            <Box component='img' src='/images/success.svg' />
            {successTitle}
          </Box>
        ) : (
          <Box
            sx={{
              padding: '120px 70px 100px',
              '.ant-form-item-row': {
                padding: '0 10px',
              },
              '.ant-form-item-label': {
                display: 'none',
              },
              '.interCallingCode': {
                whiteSpace: 'nowrap',
                '.ant-form-item-row': {
                  paddingRight: '0',
                },
                '.ant-select-selection-item': {
                  fontWeight: '500',
                  fontSize: '16px',
                },
              },
              '.phoneNumber': {
                '.label-custom': {
                  visibility: 'hidden',
                },
                '.ant-form-item-row': {
                  paddingLeft: '0',
                },
              },
              '.isPrivacyAgreement': {
                '.label-custom': {
                  visibility: 'hidden',
                },
              },
              '.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before': {
                content: 'none',
              },
            }}
          >
            <Box sx={{ fontSize: '16px', marginBottom: '40px', padding: '0 10px' }}>
              <Box sx={{ fontSize: '38px', fontWeight: 700, marginBottom: '24px', color: '#000' }}>
                {title}
              </Box>
              {discription}
            </Box>
            <Box>
              <Form
                form={form}
                layout='vertical'
                name='form_in_modal'
                scrollToFirstError
                onValuesChange={handleValuesChange}
              >
                <Row>
                  {finalFormList.map((formItem) => (
                    <Col span={customColSpanPc(formItem.name)} className={formItem.name as string}>
                      <Box
                        className='label-custom'
                        sx={{
                          color: '#151741',
                          fontSize: '14px',
                          fontWeight: 'bold !important',
                          marginBottom: '6px',
                          padding: '0 10px',
                          '.required-style': {
                            marginLeft: '4px',
                            color: '#FF0000',
                          },
                        }}
                      >
                        {customRequired(formItem.label, formItem.rules?.[0]?.['required'])}
                      </Box>
                      <FormItem {...formItem} key={formItem.name} />
                    </Col>
                  ))}
                </Row>
                <Box
                  sx={{
                    width: 360,
                    height: 48,
                    background: isFinished ? '#062CFF' : '#E2E5EE',
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 700,
                    textAlign: 'center',
                    lineHeight: '48px',
                    borderRadius: '4px',
                    cursor: isFinished ? 'pointer' : 'not-allowed',
                    marginTop: '76px',
                    marginLeft: '10px',
                  }}
                  onClick={handleSubmit}
                >
                  {submit}
                </Box>
                {/* <Box>
                  <Box id='privacyModalRoot'>{modalContent}</Box>
                </Box> */}
              </Form>
            </Box>
          </Box>
        )}
      </Box>
    )
  }, [
    showSuccessPage,
    successTitle,
    title,
    discription,
    form,
    finalFormList,
    isFinished,
    submit,
    modalContent,
    checkedFlag,
    handleValuesChange,
    customColSpanPc,
    customRequired,
    handleClick,
    onModalOpen,
  ])

  const contentDom = useMemo(() => {
    return (
      <Box className='content-box'>
        {showSuccessPage ? (
          <Box
            sx={{
              minHeight: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '5rem',
            }}
          >
            <Box component='img' src='/images/success_phone.svg' />
            {successTitle}
          </Box>
        ) : (
          <Box
            sx={{
              padding: '4.5rem 0 2.5rem',
              '.ant-form-item-row': {
                padding: '0 10px',
              },
              '.ant-form-item-label': {
                display: 'none',
              },
              '.phone': {
                whiteSpace: 'nowrap',
                '.ant-form-item-row': {
                  paddingRight: '0',
                },
                '.ant-select-selection-item': {
                  fontWeight: '500',
                  fontSize: '16px',
                },
              },
              '.phoneNumber': {
                '.label-custom': {
                  visibility: 'hidden',
                },
                '.ant-form-item-row': {
                  paddingLeft: '0',
                },
              },
              '.isPrivacyAgreement': {
                '.label-custom': {
                  visibility: 'hidden',
                },
              },
              '.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before': {
                content: 'none',
              },
            }}
          >
            <Box sx={{ fontSize: '16px', marginBottom: '40px', padding: '0 10px' }}>
              <Box
                sx={{ fontSize: '1.3rem', fontWeight: 700, marginBottom: '1.2rem', color: '#000' }}
              >
                {title}
              </Box>
              {discription}
            </Box>
            <Box>
              <Form
                form={form}
                layout='vertical'
                name='form_in_modal'
                scrollToFirstError
                onValuesChange={handleValuesChange}
              >
                <Row>
                  {finalFormList.map((formItem) => (
                    <Col span={customColSpan(formItem.name)} className={formItem.name as string}>
                      <Box
                        className='label-custom'
                        sx={{
                          color: '#151741',
                          fontSize: '14px',
                          fontWeight: 'bold !important',
                          marginBottom: '6px',
                          padding: '0 10px',
                          '.required-style': {
                            marginLeft: '4px',
                            color: '#FF0000',
                          },
                        }}
                      >
                        {customRequired(formItem.label, formItem.rules?.[0]?.['required'])}
                      </Box>
                      <FormItem {...formItem} key={formItem.name} />
                    </Col>
                  ))}
                </Row>
                <Box sx={{ display: 'flex', padding: '0.2rem' }}>
                  <Box
                    sx={{
                      width: '100%',
                      height: 48,
                      background: isFinished ? '#062CFF' : '#E2E5EE',
                      color: '#fff',
                      fontSize: '16px',
                      fontWeight: 700,
                      textAlign: 'center',
                      lineHeight: '48px',
                      borderRadius: '4px',
                      cursor: isFinished ? 'pointer' : 'not-allowed',
                      margin: '1rem 0.5rem 0',
                    }}
                    onClick={handleSubmit}
                  >
                    {submit}
                  </Box>
                </Box>
              </Form>
            </Box>
          </Box>
        )}
      </Box>
    )
  }, [
    showSuccessPage,
    successTitle,
    title,
    discription,
    form,
    isFinished,
    finalFormList,
    submit,
    handleValuesChange,
    customColSpan,
    customRequired,
    handleClick,
  ])

  useEffect(() => {
    getWebCountryAreaCode('WebCountryAreaCode')
  }, [])

  return (
    <>
      <SEO
        title={seoLocale.enquiryForm.title}
        description={seoLocale.enquiryForm.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='Careers at iMile, iMile jobs, Work for iMile, iMile delivery job, job opportunities iMile, Work at iMile'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/csp/enquiryForm' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/1c763be06e4165a95c46d50a3039cf28/d5f9a/banner.webp'
        />
        <meta property='og:title' content='Join as a Channel Service Partner' />
        <meta
          property='og:description'
          content='Discover the path to becoming a valued member of the iMile Global CSP network. Contact us now to learn how you can be a part of our worldwide network.'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      <GlobalAntdFormStyle />
      {isPc ? contentDomPc : contentDom}
      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["enquiryForm"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
