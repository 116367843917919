import React, { useCallback, useState } from 'react'
import { Modal } from 'antd'
import { useMediaQuery, Box } from '@mui/material'
import PrivacyAgreement from '../PrivacyAgreement'

export const usePrivacyNoticeModal = ({
  handleClick,
  form,
  subKey,
  setCheckedFlag,
  domRoot = 'privacyModalRoot',
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const isPc = useMediaQuery('@media (min-width: 600px)')

  const onModalOpen = useCallback(() => {
    setModalVisible(true)
  }, [])

  const onModalClose = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handleOk = useCallback(async () => {
    await form.setFieldsValue({ [subKey]: ['true'] })
    await handleClick?.()
    setCheckedFlag(['true'])
    onModalClose()
  }, [handleClick, form, subKey, setCheckedFlag])

  const content = (
    <Modal
      title='请阅读并同意以下条款'
      width={600}
      open={modalVisible}
      onOk={handleOk}
      onCancel={onModalClose}
      getContainer={() => document.querySelector?.(`#${domRoot}`) || document.body}
    >
      <PrivacyAgreement />
    </Modal>
  )

  const mobileContent = (
    <Modal
      title='请阅读并同意以下条款'
      width={343}
      open={modalVisible}
      onOk={handleOk}
      onCancel={onModalClose}
      closable={false}
      bodyStyle={{ display: 'flex', justifyContent: 'center' }}
      //   footer={() => (
      //     <Box>
      //         <>取消</>
      //         <>同意并继续提交</>
      //     </Box>
      //   )}
      getContainer={() => document.querySelector?.(`#${domRoot}`) || document.body}
    >
      <PrivacyAgreement />
    </Modal>
  )
  const modalContent = isPc ? content : mobileContent

  return {
    modalContent,
    modalVisible,
    onModalOpen,
    onModalClose,
  }
}
